import {Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';

import {ModalSize, SuiModalService} from '@angular-ex/semantic-ui';

import {User} from '../../../../core/models/users/users.model';
import {Topic} from '../../../../core/models/topics/topics.model';
import {Article} from '../../../../core/models/articles/articles.model';
import {TopicsFilter} from '../../../../core/models/topics/topics.filter';
import {UsersService} from '../../../../core/services/users/users.service';
import {TopicsService} from '../../../../core/services/topics/topics.service';
import {ArticlesFilter} from '../../../../core/models/articles/articles.filter';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {ArticlesService} from '../../../../core/services/articles/articles.service';
import {DuplicateModal} from '../../components/duplicate-modal/duplicate-modal.component';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article.component.html',
  styleUrls: ['./create-article.component.css']
})
export class CreateArticleComponent implements OnInit {
  @ViewChild('articleFormComponent') articleForm: any;
  public topics!: Topic[];
  public subjectAreas!: Topic[];
  public institutions!: Institution[];

  private user!: User;

  constructor(
    private router: Router,
    private topicsService: TopicsService,
    private articlesService: ArticlesService,
    private modalService: SuiModalService,
    private institutionsService: InstitutionsService,
    private usersService: UsersService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.getTopics();
    this.getSubjectAreas();
    this.getInstitutions();
  }

  private getCurrentUser() {
    this.usersService.getCurrentUser().then((user: User) => {
      this.user = user;
    });
  }

  private getTopics(): void {
    const queryParams: TopicsFilter = {
      isSubjectArea: 'false',
    };

    this.topicsService.getAll(queryParams).then((topics) => {
      this.topics = topics.docs;
    });
  }

  private getSubjectAreas(): void {
    const queryParams: TopicsFilter = {
      isSubjectArea: 'true',
    };

    this.topicsService.getAll(queryParams).then((subjectAreas) => {
      this.subjectAreas = subjectAreas.docs;
    });
  }

  public createArticle(): void {
    // Build filter with links from new article logical or
    // If there are articles with same link show popup with them
    this.findPossibleDuplicates().then((articlesData) => {
      const possibleDuplicateArticlesData = articlesData.docs;
      if (possibleDuplicateArticlesData.length > 0) {
        // There are possible duplicates -> Open modal with duplicates
        this.modalService
          .open(new DuplicateModal('ACHTUNG! Es könnten Duplikate vorliegen!', possibleDuplicateArticlesData, ModalSize.Large))
          .onApprove(() => {
            // user decides that the article is not a duplicate -> add article
            this.postArticle();
          })
          .onDeny(() => {
            // user decides that the article is a duplicate
            this.toastService.warning('Artikel nicht hinzugefügt', 'Der Artikel konnte nicht hinzugefügt werden.');
          });

      } else {
        // There are no possible duplicates -> add article
        this.postArticle();
      }
    });
  }

  private findPossibleDuplicates(): Promise<any> {
    const articlesHttpParamsDTO: ArticlesFilter = {only: ''};

    articlesHttpParamsDTO.links = this.articleForm.getValues().links;

    return this.articlesService.getAll(articlesHttpParamsDTO).toPromise();
  }

  private postArticle(): void {
    const article: Article = Object.assign(this.articleForm.getValues());
    article.authorId = this.user._id;

    this.articlesService.createOne(article).then(() => {
      this.toastService.success('Artikel erstellt', 'Der Artikel wurde erfolgreich erstellt.');
      this.router.navigate(['author/articles']);
    }).catch(error => {
      this.toastService.error('Artikel nicht erstellt', 'Der Artikel konnte nicht ersteltl werden.', error);
    });
  }

  private getInstitutions() {
    this.institutionsService.getAll().then((instData) => {
      this.institutions = instData.docs;
    });
  }
}
