<div class="ui grid">
  <div class="row">
    <div class="column">
      <h1 class="ui header">Artikel erstellen:</h1>
      <app-article-form [topics]="topics" [subjectAreas]="subjectAreas" [institutions]="institutions"
                        #articleFormComponent></app-article-form>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <button class="ui primary button" type="submit" (click)="createArticle()"
              [disabled]="!articleFormComponent.articleForm.valid">
        Erstellen
      </button>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <div>* erforderlich</div>
      <div>** erforderlich, außer bei dauerhafter Ausschreibung</div>
    </div>
  </div>
</div>

