import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SuiPaginationModule} from '@angular-ex/semantic-ui';

import {AuthorComponent} from './author.component';
import {SharedFormsModule} from '../../shared/forms/shared-forms.module';
import {AuthorRoutingModule} from './author-routing.module';
import {EditArticleComponent} from './pages/edit-article/edit-article.component';
import {CreateArticleComponent} from './pages/create-article/create-article.component';
import {SharedComponentsModule} from '../../shared/components/shared-components.module';
import {DuplicateModalComponent} from './components/duplicate-modal/duplicate-modal.component';

@NgModule({
  declarations: [
    AuthorComponent,
    CreateArticleComponent,
    EditArticleComponent,
    DuplicateModalComponent,
  ],
  imports: [
    AuthorRoutingModule,
    CommonModule,
    SharedComponentsModule,
    SuiPaginationModule,
    SharedFormsModule,
  ]
})
export class AuthorModule {
}
