<div class="header">{{ modal.context.title }}</div>
<div class="content">
  <h3 class="ui header">Mögliche Duplikate:</h3>
  <div class="ui divided items">
    <div class="item" *ngFor="let article of modal.context.possibleDuplicateArticles">
      <div class="content">
        <a class="header" target="_blank" [routerLink]="['/articles', article._id]"><i
          class="copy outline icon"></i>{{ article?.title }}</a>
        <div class="meta" *ngIf="article?.validDate && !article?.permanentFlag">Fristablauf:
          {{ article?.validDate | date }}
        </div>
        <div class="meta" *ngIf="article?.permanentFlag">Kein Fristablauf!</div>
        <div class="meta">Anmeldung bis: {{ article?.registerDate | date }}</div>
        <div class="extra">
          <a class="link" *ngFor="let link of article?.links" (click)="onNavigate(this.link)"
             (keydown)="onNavigate(this.link)" tabindex="-1">{{ link }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button class="ui green button" (click)="modal.approve(undefined)">Trotzdem hinzufügen!</button>
  <button class="ui red button" (click)="modal.deny(undefined)">Nicht hinzufügen!</button>
</div>
