import {Component, OnInit} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {clients} from '../../core/constants/clients.config';
import {clientRoles} from '../../core/constants/client-roles.config';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.css']
})
export class AuthorComponent implements OnInit {
  public clientRoles = clientRoles;
  public userRoles: string[] = [];

  constructor(
    private keycloakService: KeycloakService,
  ) {
  }

  ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
  }
}
