import {Component} from '@angular/core';

import {ComponentModalConfig, ModalSize, SuiModal} from '@angular-ex/semantic-ui';

import {Article} from '../../../../core/models/articles/articles.model';

interface IDuplicateModalContext {
  title: string;
  possibleDuplicateArticles: Article[];
}

@Component({
  selector: 'app-duplicate-modal',
  templateUrl: './duplicate-modal.component.html',
  styleUrls: ['./duplicate-modal.component.css']
})
export class DuplicateModalComponent {

  constructor(public modal: SuiModal<IDuplicateModalContext, void, void>) {
  }

  onNavigate(urlToOpen: string) {
    let url = '';
    if (!/^http[s]?:\/\//.test(urlToOpen)) {
      url += 'http://';
    }

    url += urlToOpen;
    window.open(url, '_blank');
  }
}

export class DuplicateModal extends ComponentModalConfig<IDuplicateModalContext, void, void> {
  constructor(title: string, possibleDuplicateArticles: Article[], size = ModalSize.Small) {
    super(DuplicateModalComponent, {title, possibleDuplicateArticles});

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
